.App {
  text-align: center;
  height: 100vh;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #090b0c;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.main_form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 500px;
  width: 90%;
  padding: 5%;
  box-sizing: border-box;
  background-color: #171a1c;
  border-radius: 10px;
}

.main_form img { 
  width: 70%;
  margin-bottom: 50px;
}

.main_form input {
  font-size: 18px;
  padding-inline: 10px;
  padding-block: 15px;
  width: 100%;
  border: 0px;
  background-color: #171a1c !important;
  color: #FFFFFF !important;
  margin-bottom: 15px;
  border-radius: 5px;
  box-sizing: border-box;
  border: 1px solid #FFFFFF;
  outline: none;
}

.main_form button {
  font-size: 18px;
  padding-inline: 10px;
  padding-block: 15px;
  width: 100%;
  border: 0px;
  color: #FFFFFF;
  background-color: #05C46B;
  border-radius: 5px;
  box-sizing: border-box;
  cursor: pointer;
  transition: 0.1s ease-in-out;
}

.main_form button:hover {
  opacity: 0.8;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
