@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@font-face {
  font-family: "Nexa";
  src: url("./fonts/NexaThinItalic.otf");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("./fonts/NexaLight.otf");
  font-weight: 300;
}

@font-face {
  font-family: "Nexa";
  src: url("./fonts/NexaLightItalic.otf");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("./fonts/NexaRegular.otf");
  font-weight: 500;
}

@font-face {
  font-family: "Nexa";
  src: url("./fonts/NexaRegularItalic.otf");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("./fonts/NexaBold.otf");
  font-weight: 700;
}

@font-face {
  font-family: "Nexa";
  src: url("./fonts/NexaBoldItalic.otf");
  font-weight: 700;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("./fonts/NexaHeavy.otf");
  font-weight: 800;
}

@font-face {
  font-family: "Nexa";
  src: url("./fonts/NexaHeavyItalic.otf");
  font-weight: 800;
  font-style: italic;
}

@font-face {
  font-family: "Nexa";
  src: url("./fonts/NexaBlack.otf");
  font-weight: 900;
}

@font-face {
  font-family: "Nexa";
  src: url("./fonts/NexaBlackItalic.otf");
  font-weight: 900;
  font-style: italic;
}


body {
  margin: 0;
  font-family: 'Montserrat' ,"Nexa", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, select {
  font-family: 'Montserrat' ,"Nexa", sans-serif;
}
